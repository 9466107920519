import React, {useCallback, useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {useHistory} from 'react-router';

import {axios} from '../libs';
import {defaultPaginationOption, defaultColumnsOption} from '../config';


export const DataTable = ({
  navigateTo,
  url,
  dataKey,
  tableHeading,
  columns,
  onDelete,
  onCopy,
  onEdit,
  onData,
  onPreview,
  refetch = false,
  actions,
  isPreview = false,
  defaultSorted,
}) => {
  const [data, setData] = useState([]);
  const [paginationOptions, setpaginationOptions] = useState({});
  const {push} = useHistory();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(url);
      console.log(JSON.stringify(response, null, 2))
      setpaginationOptions({...defaultPaginationOption, totalSize: response.total});
      setData(response[dataKey]);
    } catch (error) {
      return error;
    }
  }, [dataKey, url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch, fetchData]);

  const onSearch = async ({target: {value}}) => {
    const updatedUrl = `${url}?name=${value}`;
    const {...data} = await axios.get(updatedUrl);
    setData(data[dataKey]);
  };

  const onTableChange = (type, {sortField, sortOrder, page, sizePerPage}) => {
    if (type === 'sort') {
      sortData(sortField, sortOrder);
    } else {
      paginateData(page, sizePerPage);
    }
  };

  const sortData = async (sortField, sortOrder) => {
    const sortPrefix = sortOrder === 'asc' ? '' : '-';
    const updatedUrl = `${url}?sort=${sortPrefix}${sortField}`;
    const {...data} = await axios.get(updatedUrl);
    setData(data[dataKey]);
  };

  const paginateData = async (page, sizePerPage) => {
    const updatedUrl = `${url}?limit=${sizePerPage}&page=${page}`;
    const {...data} = await axios.get(updatedUrl);
    setpaginationOptions({...defaultPaginationOption, page, sizePerPage});
    setData(data[dataKey]);
  };

  const columnDataFromatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="row align-items-center">
        {/* {onDelete && <i onClick={() => onDelete(row)} className="col delete-icon fas fa-trash-alt fa-lg p-2"></i>}
        {onEdit && <i onClick={() => onEdit(row)} className="col edit-icon fas fa-pencil-alt fa-lg p-2"></i>}
        {onData && <i onClick={() => onData(row)} className="col fas fa fa-file fa-lg p-2"></i>}
        {isPreview && (
          <i onClick={() => push(`/chatbot/conversation/${row._id}`)} className="col fa fa-eye fa-lg p-2"></i>
        )} */}

        {onDelete && 
          <span onClick={() => onDelete(row)} className="col btn-link-page">Delete</span>}
        {onPreview && 
          <span onClick={() => onPreview(row)} className="col btn-link-page">Preview</span>
        }
        {onCopy && 
          <span onClick={() => onCopy(row)} className="col btn-link-page">Copy</span>
        }
      
        {onData && 
          <button onClick={() => onData(row)} className="col btn-page">Data</button>}
            {onEdit && 
          <button onClick={() => onEdit(row)} className="col btn-page">Edit</button>}
        
       {/*  {isPreview && (
          <i onClick={() => push(`/chatbot/conversation/${row._id}`)} className="col fa fa-eye fa-lg p-2"></i>
        )} */}
      </div>
    );
  };

  const columnFormatter = {
    dataField: '',
    text: 'Actions',
    formatter: columnDataFromatter,
  };

  const tableEvents = {
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (!navigateTo) return;
        if (dataKey === 'documents') {
          window.open(`${navigateTo}/${row._id}/edit`, '_blank');
        } else {
          push(`${navigateTo}/${row._id}`);
        }
      },
    },
  };

  return (
    <>
    {/* <div className="row justify-content-end">
      <div className="col-sm-4 mb-2">
        <label className="control-label">Search</label>
        <input onChange={onSearch} placeholder="Search" className="form-control" />
      </div>
      <div className="col-sm-12">
        <BootstrapTable
          remote
          hover
          striped
          keyField="_id"
          noDataIndication="No data found"
          data={data}
          columns={columns
            .map((column) => ({
              ...column,
              ...tableEvents,
              ...defaultColumnsOption,
            }))
            .concat({...actions, ...columnFormatter})}
          rowClasses="table-row"
          onTableChange={onTableChange}
          pagination={paginationFactory(paginationOptions)}
        />
      </div>
    </div>
 */}
    
      <div className="row justify-content-start">
      <div className="col-sm-4 mb-2 datasearch">
        {/* <label className="control-label">Search</label> */}
        <input onChange={onSearch} placeholder="Search" className="form-control" />
      </div>
      <div className="col-sm-12">
        <BootstrapTable
          remote
          hover
          striped
          keyField="_id"
          noDataIndication="No data found"
         data={[...data].reverse()}
          columns={columns
            .map((column) => ({
              ...column,
              ...tableEvents,
              ...defaultColumnsOption,
            }))
            .concat({...actions, ...columnFormatter})}
          rowClasses="table-row"
          onTableChange={onTableChange}
          pagination={paginationFactory(paginationOptions)}
       
        />
      </div>
      </div>
      </>
 
 );
};
