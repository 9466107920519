import React, {useEffect, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {axios} from '../libs';
import {User} from '../states/actions';
import { GlobalStateContext } from '../components/GlobalState';
export const Sidebar = () => {

  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const user = useSelector(User.get());
  const {replace} = useHistory();
  useEffect(() => {
    window.initSidebar();
  }, []);

   console.log(window)

  const [genmenu, setGenmenu] = useState(true);
  const [appmenu, setAppmenu] = useState(false);


  const logout = async () => {
    await axios.get('/logout');
    replace('/login');
  };
  const setMenu = () => {
    setAppmenu(appmenu ? false : true, appmenu ? true : false); 
    setGenmenu(genmenu ? false : false)
  }
  const setMenuGen = () => {
    setAppmenu(genmenu && appmenu ? false : false, appmenu && !genmenu ? true : false); 

    setGenmenu(genmenu ? false : true, genmenu ? true : false)
  }

  
  /*  write a function to set global state for minmenu to true if genmenu and appmenu are false */
  const setMinMenu = () => {
    if (genmenu === false && appmenu === false) {
      setGlobalState({...globalState, minmenu: true})
    } else {
      setGlobalState({...globalState, minmenu: false})  
    }
  }
 
  useEffect(() => {
    setMinMenu()
  }, [genmenu, appmenu])



   
  


  return (

    <div className={`${!genmenu && !appmenu ? 'sm' : ''} menu-container`}>


        <div className="navbar header">
          <Link to="/" className="site_title">
            {(!genmenu && !appmenu) ? (
            <span> <img width={56} src="/images/logo.png" alt="..." /> </span>
            ):(
              <span> <img width={106} src="/images/logo_l.png" alt="..." /> </span>
            )}
          </Link>
        </div>


     


      
   


        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">


           <div className={`${genmenu ? 'open' : ''} menu_section general`}>

              <ul className="nav side-menu">

              <li>
                  <Link to="/users" title="Admin">
                  <i className="icon"><img src="/images/admin_icon.png" alt="..." /></i>
                   {genmenu ? "ADMIN" : ""}
                     
                  </Link>
                </li>
                <li>
                  <Link to="/clients" title="Clients">
                  <i className="icon"><img src="/images/admin_icon.png" alt="..." /></i>
                   {genmenu ? "CLIENTS" : ""}
                     
                  </Link>
                </li>

                <li>
                  <Link to="/chatbots" title="Apps">
                  <i className="icon"><img src="/images/documents_icon.png" alt="..." /></i>
                   {genmenu ? "APPS" : ""}
                     
                  </Link>
                </li>
                
                <li>
                  <Link to="/documents" title="Documents">
                  <i className="icon"><img src="/images/documents_icon.png" alt="..." /></i>
                  
                  {genmenu ? "Documents" : ""}
                  </Link>
                </li>

                <li>
                  <Link to="/sheets" title="Sheets">
                  <i className="icon"><img src="/images/documents_icon.png" alt="..." /></i>
                  
                  {genmenu ? "Sheets" : ""}
                  </Link>
                </li>

                <li>
                  <Link to="/" title="New app">
                  <i className="icon"><img src="/images/addapp1.png" alt="..." /></i> 
                    
                    {genmenu ? "New app" : ""}
                   
                  </Link>
                </li>
              

              </ul>


              <span className='menuswitcher' onClick={()=> {setMenuGen()}}>
                {!genmenu ? <i className="fas fa-angle-right"></i> :
                <i className="fas fa-angle-left"></i>
                }
            </span>

         </div>
     
          
          { globalState.appid !== undefined && 
          <div className={`${appmenu ? 'open' : ''} menu_section`}>

            <ul className="nav side-menu">

             
            

              
              <li>
                <Link title="Builder" to={`/chatbot-builder/${globalState.appid}`} onClick={console.log(window.appid)}>
                  <i className="icon"><img src="/images/builder_icon.png" alt="..." /></i> 
                  {appmenu ? "Builder" : ""}
                </Link>
              </li>

              <li>
                <Link title="Data" to={`/chatbot/conversation/${globalState.appid}`} onClick={console.log(window.appid)}>
                <i className="icon"><img src="/images/data_icon.png" alt="..." /></i> 
                {appmenu ? "Data" : ""}
                </Link>
              </li>
             

              <li>
                <Link title="App settings" to={`/chatbots/${globalState.appid}`} onClick={console.log(window.appid)}>
                <i className="icon"><img src="/images/settings_icon.png" alt="..." /></i> 
                 {appmenu ? "Settings" : ""}
                </Link>
              </li>

             
            </ul>



            <span className='menuswitcher' onClick={()=> {setMenu()}}>
                {appmenu ? <i className="fas fa-angle-left"></i> :
                <i className="fas fa-angle-right"></i>
                }
            </span>

          </div>
          }
        </div>



    
        <div className="menu_bottom">
        
            <ul className="nav side-menu">
           
              <li>
                <Link to="/">
                <i className="icon"><img src="/images/help_icon.png" alt="..." /></i> 
                    Help
                </Link>
                </li>

              <li>
               

                  <span className="" title="Logout">
                  { !globalState.minmenu  &&
                   <>
                    {user.fullName}
                    </>
                  }
                  <i onClick={logout}  className="icon"><img src="/images/logout_icon.png" alt="..." /></i> 
                  </span>
                </li>

              

             
        </ul>

        </div>

    </div>
  );
};
