import {axios} from '../../libs';
import {defaultsDeep as _defaultsDeep} from 'lodash';
import {chatbotSlice} from '../slices';
import store from '../../store';

const Actions = chatbotSlice.actions;
const {dispatch, getState} = store;

const get = (type = null) => {
  if (type === 'current') {
    return getState().chatbot;
  }
  return (state) => state.chatbot;
};

const getBlocksToReview = (blocks = null) => {
  let variables = [];
  Object.values(blocks ?? getState().chatbot.blocks).forEach((item) => {
    //console.log(item.type);
    if (item.type === 'inputBlock' || item.type === 'multipleChoiceBlock') {
     // if (item.data?.hasOwnProperty('variable')) {
        variables.push({label: item.data.variable, value: item.data._id, content: item.data._id, id: item.data._id});
     // }
    }else{}
  });
  return variables;
};

/* If there is a need for teleport block variables to be imported
const getBlocksToTeleport = (blocks = null) => {
  let variables = [];
  Object.values(blocks ?? getState().chatbot.blocks).forEach((item) => {
    //console.log(item.type);
    if (item.type === 'inputBlock' || item.type === 'multipleChoiceBlock') {
     // if (item.data?.hasOwnProperty('variable')) {
        variables.push({label: item.data.variable, value: item.data._id, content: item.data._id, id: item.data._id});
     // }
    }else{}
  });
  return variables;
};*/
const getBlockVariables = (blocks = null) => {
  let variables = [];

  variables.push(
    // {
    //   label: 'email_confrimation_dateofclick',
    //   value: 'email_confrimation_dateofclick',
    //   content: 'Email confirmation Date of click',
    // },
    {
      label: 'email_confrimation_timeofclick',
      value: 'email_confrimation_timeofclick',
      content: 'Email confirmation Time of click',
    },
  );
  Object.values(blocks ?? getState().chatbot.blocks).forEach((item) => {
    if (item.type !== 'conditionBlock') {
      if (item.data?.hasOwnProperty('variable')) {

        variables.push({label: item.data.variable, value: item.data._id, content: item.data._id, id: item.data._id});
        if (item.type === 'decisionBlock') {
          variables.push(
            {
              label: `${item.data.variable}-dateofclick`,
              value: item.data.variable,
              content: `${item.data.variable} Date of click`,
            },
            {
              label: `${item.data.variable}-timeofclick`,
              value: item.data.variable,
              content: `${item.data.variable} Time of click`,
            },
          );
        }

      }
      if (item.type === 'calculationBlock') {
        variables.push(
          {
            label: `Today`,
            value: `today`,
            content: `today`,
          },
        );
      }
      if (item.type === 'dataBlock' && item.data.data) {
        Object.entries(item.data.data).forEach(([text, value]) => {
          variables.push({label:value.text, value: value.value, content: item.data.content});
        });
      }
      if (item.type === 'loginBlock') {
          variables.push(
          {
            label: 'firstname',
            value: 'firstname',
            content: 'firstname',
          },
          {
            label: 'lastname',
            value: 'lastname',
            content: 'lastname',
          },
          {
            label: 'phone',
            value: 'phone',
            content: 'phone',
          },
          {
            label: 'email',
            value: 'email',
            content: 'email',
          }, 
          {
            label: 'pid',
            value: 'pid',
            content: 'pid',
          },
        )
      }
      if (item.type === 'calendarBlock') {
        variables.push(
        {
          label: 'slot',
          value: 'slot',
          content: 'slot',
        },
        {
          label: 'slot_date',
          value: 'slot_date',
          content: 'slot_date',
        },
      )
      }
      if (item.type === 'matchBlock') {
        variables.push(
          { label: 'lawyer', value: 'lawyer', content: 'lawyer' },
          { label: 'selected_service_price', value: 'selected_service_price', content: 'selected_service_price' },
          { label: 'partner_email', value: 'partner_email', content: 'partner_email' },
          { label: 'partner_company_name', value: 'partner_company_name', content: 'partner_company_name' },
          { label: 'partner_company_reg_id', value: 'partner_company_reg_id', content: 'partner_company_reg_id' },
          { label: 'partner_company_vat_id', value: 'partner_company_vat_id', content: 'partner_company_vat_id' },
          { label: 'partner_vat', value: 'partner_vat', content: 'partner_vat' },
          { label: 'partner_company_address', value: 'partner_company_address', content: 'partner_company_address' },
          { label: 'partner_hourlyprice', value: 'partner_hourlyprice', content: 'partner_hourlyprice' }
        );
      }
    }
    // return item.type !== 'conditionBlock' && item.data?.hasOwnProperty('variable');
  });
  return variables;
};

const set = (data, callAPI = false) => {
  if (callAPI) {
    const {blocks, ...chatbot} = getState().chatbot;
    const _chatbot = _defaultsDeep(data, chatbot);

    axios.put(`/chatbot/${_chatbot._id}`, _chatbot);
  }
  return dispatch(Actions.set(data));
};


const setData = (data, callAPI = false) => {
  const _data = _defaultsDeep({data}, {data: getState().chatbot.data});
  if (callAPI) {
    axios.post(`/chatbot`, _data);
  }
  return dispatch(Actions.set(_data));
};



//Vaja eemaldada v hiljem kasutada
const getChatbot = (id) => {
  return getState().chatbot;
};
const setChatbotData = (id, data, prevdata, callAPI = true) => {


  var dataname = Object.getOwnPropertyNames(data)[0];
  var data2 = prevdata[dataname];


  const _data = prevdata.assign
  ? Object.assign({}, prevdata, data)
  : _defaultsDeep({data}, prevdata);
  
 
  const merged = Object.assign({}, data2, _data.data.progress)
  const data4 = {
  [dataname]: merged
  };
  console.log(data4)
  

  if (callAPI) {
  //  console.log('settingDATA: ' + id, _data.data)
    axios.saveBot(id, data4);
  }
  return dispatch(Actions.setChatbotData(data));
};


const setChatbotData0 = (id, data, callAPI = true) => {

 /*  console.log(id, data)
  const prevdata = getChatbot();
  const _data = data.assign
  ? Object.assign({}, prevdata, data)
  : _defaultsDeep({data}, prevdata);
  
  console.log(_data.data)

  if (callAPI) {
    axios.saveBot(id, _data.data);
  }
  return dispatch(Actions.setChatbotData0(data)); */


  dispatch(Actions.setChatbotData0(data));
  const prevdata = getChatbot();
  const updatedData = { ...prevdata, ...data };


  if (callAPI) {
    axios.saveBot(id, updatedData.data);
  }
  return; 

};



const getBlock = (id) => {
  return getState().chatbot.blocks[id];
};

const setBlock = (data) => {
  const block = _defaultsDeep(data, getBlock(data.id));
  return dispatch(Actions.setBlock(block));
};

const setBlocks = (blocks) => {
  dispatch(Actions.setBlocks(blocks));
  return blocks;
};

const removeBlockDecision = (blockId, id) => {
  return dispatch(Actions.removeBlockDecision({blockId, id}));
};
const removeBlockReviews = (blockId, id) => {
  return dispatch(Actions.removeBlockReviews({blockId, id}));
};
const removeSecondaryButton = (blockId, id) => {
  return dispatch(Actions.removeSecondaryButton({blockId, id}));
};
const removeBlockVariable = (blockId, id) => {
  return dispatch(Actions.removeBlockVariable({blockId, id}));
};
const removeBlockFlow = (blockId, id) => {
  return dispatch(Actions.removeBlockFlow({blockId, id}));
};

const removeDataBlock = (blockId, key) => {
  return dispatch(Actions.removeDataBlock({blockId, key}));
};

const removeBlockCondition = (blockId, id) => {
  return dispatch(Actions.removeBlockCondition({blockId, id}));
};

const clear = () => {
  dispatch(Actions.clear());
};

const setBlockData = async (id, data, callAPI = false) => {
  let response;

  const block = data.assign
    ? Object.assign({}, getBlock(id), {data: data.assign})
    : _defaultsDeep({data}, getBlock(id));

  if (callAPI) {
    response = await axios.post(`/block/${block.data.type}`, block.data);
  }
  dispatch(Actions.setBlockData(block));
  return response;
};

const removeBlock = async (id, callAPI = false) => {
  if (callAPI) {
    axios.delete('/block', {id});
  }
  return dispatch(Actions.removeBlock(id));
};

const fetch = async (chatbotId) => {
  try {
    let {chatbot} = await axios.get(`/chatbot/${chatbotId}`);
    const blocks = formatBlocks(chatbot);
    return {...chatbot, blocks: blocks};
  } catch (error) {
    console.error(error);
  }
};

const formatBlocks = (chatbot) => {
  let blocks = {};
  chatbot.blocks.forEach((block) => {
    if (block.type === 'handle') {
      blocks[block._id] = {
        ...block.meta.handle,
        data: {type: 'handle'},
      };
    } else {
      blocks[block._id] = {
        id: block._id,
        type: `${block.type}Block`,
        position: block.meta.position,
        data: block,
      };
    }
  });
  return blocks;
};

const fetchDocuments = async () => {
  try {
    const {documents} = await axios.get('user/documents');
    return {documents};
  } catch (error) {
    return error;
  }
};
const fetchSheets = async () => {
  try {
    const {documents} = await axios.get('user/sheets');
    return {documents};
  } catch (error) {
    return error;
  }
};

export const Chatbot = {
  get,
  set,
  setData,
  clear,
  fetch,
  getBlock,
  setBlock,
  setBlockData,
  setBlocks,
  removeBlockDecision,
  removeBlockReviews,
  removeBlockCondition,
  removeBlock,
  getBlockVariables,
  fetchDocuments,
  fetchSheets,
  removeDataBlock,
  formatBlocks,
  removeBlockFlow,
  removeBlockVariable,
  removeSecondaryButton,
  getBlocksToReview,
  getChatbot, 
  setChatbotData,
  setChatbotData0,
};
