import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, SelectComp, TextareaComponent, RichTextareaComponent} from './Components'
import {axios} from '../../libs/axios';



export const ScoroBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());


  if (!block) {
    return null;
  }
 
  const {data} = block;

  return (
    <>
        <IntNodeComponent name="Scoro" icon="scoro" data={data} node={node} start={start}/>
    </>
  );
});
export const ScoroEditor = ({nodeid, block, variableoptions}) => {

  const [lawyerList, setLawyers] = useState([]);

  const [managerList, setManager] = useState([]);
  const {data,  data: {user = {}, lawyers = {}, manager = {}}} = block;

  useEffect(() => {
    (async () => {
      const { lawyers } = await axios.get('booking/lawyers');
  
      // Prepare the new lawyer entry
      const lawyerEntry = { label: "lawyer", value: 0, email: "lawyeremail" };
  
      // Map lawyers and add the new entry to the beginning
      const mappedLawyers = lawyers?.map((i) => {
        return { label: i.full_name, value: i.id, email: i.email };
      });
  
      // Add the new entry to the beginning of the array
      mappedLawyers.unshift(lawyerEntry);
  
      setLawyers(mappedLawyers);
  
      const mappedManagers = lawyers?.map((i) => {
        return { label: i.full_name, value: i.id };
      });
  
      // Add the new entry to the beginning of the managers array
      mappedManagers.unshift(lawyerEntry);
  
      setManager(mappedManagers);
    })();
  }, []);


  const variableOptions = Chatbot.getBlockVariables();


  let productOptions = [
    {
      label: 'Contract',
      value: 24,
      content: 24,
    },
    {
      label: 'App',
      value: 187,
      content: 187,
    },
  ];


  let statusList = [
    {
      label: 'Pending',
      value: 'pending',
      content: 'pending',
    },
    {
      label: 'In progress',
      value: 'inprogress',
      content: 'inprogress',
    },
  ];

  let languageOptions = [
    {
      label: 'Estonian',
      value: 94,
      content: 'eesti',
    },
    {
      label: 'Russian',
      value: 95,
      content: 'vene',
    },
    {
      label: 'English',
      value: 97,
      content: 'inglise',
    },
  ];

  if (!block) {
    return null;
  }

  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
          
          <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>


          <div className='section'>SCORO</div>

          {/* <InputComponent data={data} name="Price" dataname="amount" onBlur={handleBlur}/> */}

         {/*  <SelectComp data={data} name="Price" dataname="amount" placeholder="Select price" options={variableoptions} block={block} value="label"/> */}


          {/* TODO fix save option value to label and remove optional value, check if there are some that work differently */}

          <i>Slot is defined previously in calendar block. I have not defined it here, as it should always be the same variable. This is now automatically used by scoro. But it needs to exist. The same goes for customer data: firstname, lastname, phone, pid, phone etc. These can be taken in automatically, and selections can be removed from here in the future. Leavin them in for now.</i>
     
          <SelectComp data={data} name="Product" dataname="product" placeholder="Select" options={productOptions} block={block} value="value"/>

          <SelectComp data={data} name="Price" dataname="price" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="First Name" dataname="first_name" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Last Name" dataname="last_name" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Email" dataname="email" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Phone" dataname="phone" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Adress" dataname="adress" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Language" dataname="language" placeholder="Select" options={languageOptions} block={block} value="value"/>

          <SelectComp data={data} name="ID code" dataname="id_code" placeholder="Select" options={variableoptions} block={block} value="label"/>

          <SelectComp data={data} name="Manager" dataname="manager" placeholder="Select" options={managerList} block={block}/>




          <InputComponent data={data} name="Case Title" dataname="caseTitle" onBlur={handleBlur}/>

          <SelectComp data={data} name="Status" dataname="status" placeholder="Select" options={statusList} block={block} value="value"/>

          {/* <InputComponent data={data} name="Description" dataname="caseDescription" onBlur={handleBlur}/> */}
          <RichTextareaComponent data={data} name="Description" dataname="caseDescription" onBlur={handleBlur} block={block}/>

          <SelectComp data={data} name="Lawyer" dataname="lawyers" dataname2="lawyerEmails" placeholder="Select" options={lawyerList} block={block} value="value" value2="email" multi='true'/>



                  
  </>
  

)
};